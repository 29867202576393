@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply w-full max-w-full bg-backgroundDark;
    font-family: "Mod-Aber-Mono-Regular", sans-serif;
  }
}

@font-face {
  font-family: "Mod-Aber-Mono-Regular";
  src: url("../src/assets/fonts/Mod-Aber-Mono-Regular-v2.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}
